import React, {  useState } from "react";

import { PasswordInputField, EmailInputField, SwitchInput } from "../../Components/Core/InputField"
import { Link } from "react-router-dom";



const LoginForm = ({ loginClicked, loginData, isLoading, error, isError, isSuccess }) => {
  const [userCredentials, setUserCredentials] = useState({
    email: "",
    password: "",
  });
  const [remember_me, setRememberMe] = useState(false);



  const toggleRemberMe = () => {
    setRememberMe(!remember_me);
  };
  return (
    <div className="login-module">
      <div className="login-module-header">
        <Link className="logo">
           <img src="./logo.png" width={171} height={70} alt={process.env.REACT_APP_COMPANY_NAME} />
        </Link>
      </div>
      <div className="login-module-body">
        <form onSubmit={(e) => { e.preventDefault(); loginClicked(userCredentials, remember_me) }}  >


          <div className="form-group">
            <EmailInputField
              value={userCredentials.email}
              required
              onChange={(e) =>
                setUserCredentials({
                  ...userCredentials,
                  email: e.target.value,
                })
              }
            />
          </div>
          <div className="form-group">
            <PasswordInputField
              value={userCredentials.password}
              required={true}
              onChange={(e) =>
                setUserCredentials({
                  ...userCredentials,
                  password: e.target.value,
                })
              }
            />
            <div className="forget-password text-right">
              <a href="/forget-password">Forgot Password?</a>
            </div>
          </div>
          <div className="from-group">
            <SwitchInput
              defaultChecked={remember_me}
              onClick={toggleRemberMe}
            />
          </div>
          <div className="submit-btn">
            <button
              className="btn btn-primary"
              type="submit"
              disabled={isLoading}
            >
              {isLoading ? (
                <span
                  className="spinner-border spinner-border-sm"
                />
              ) : <span>Log In</span>}
            </button>
          </div>
        </form>


        {isError && (
          <div>
            <br />
            <div className="alert alert-danger" role="alert">
              there is something error
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default LoginForm
